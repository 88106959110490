<template>
  <div>
    <div v-if="preventText !== ''" v-html="preventText"></div>
    <div v-if="preventText === ''">
      <h1>Документы для уплаты НДФЛ</h1>
      <div v-if="sent === false">
        <p>Пожалуйста, заполните форму и прикрепите подтверждающие Документы.</p>
        <p>Фото личных документов: </p>
        <ol>
          <li>Разворот паспорта с&nbsp;серией и&nbsp;номером.</li>
          <li>Сканированная копия ИНН или&nbsp;скриншот с&nbsp;сайта налогового органа, где отображены паспортные данные.</li>
        </ol>
        <p><b>При&nbsp;заполнении формы необходимо вносить данные без&nbsp;сокращений &ndash; так же, как и в самих документах.</b></p>
      </div>
      <form action="" method="post" enctype="multipart/form-data" class="verification__form">
        <div class="alert alert--danger" v-if="error">
          <p v-html="error"></p>
        </div>
        <div class="alert alert--success" v-if="success">
          <p v-html="success"></p>
        </div>
        <div v-if="sent === false">
          <hr>
          <h3>Укажите информацию о себе</h3>
          <br>
          <UserDataForm :isPassportIssuerCode="isPassportIssuerCode" :isBirthPlace="isBirthPlace" />
          <hr>
          <div class="row">
            <div class="col-md-3">
              <label>Сканы документов</label>
            </div>
            <div class="col-md-8">
              <div class="form-group">
                <div class="file-field">
                  <input
                    type="file"
                    ref="files"
                    multiple
                    @change="handleFilesUpload()"
                    accept="image/jpeg,image/png,image/webp,application/pdf"
                  >
                </div>
              </div>
              <small>Вы можете выбрать несколько файлов удерживая клавишу <strong>Ctrl&nbsp;(Windows)</strong> или <strong>Cmd&nbsp;(macOS)</strong>.<br> Общий размер файлов <strong>не&nbsp;должен превышать 30&nbsp;МБ</strong>.</small>
            </div>
          </div>
          <br>

          <button class="button button--rounded button--large" @click.prevent="submit()" :disabled="sending">Оформить</button>

          <div class="row">
            <div class="col-sm-8 col-md-7">
              <div class="form__sending-text" v-if="sending">Пожалуйста, подождите. Идёт отправка...</div>
            </div>
          </div>

          <br>
          <br>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import UserDataForm from './UserDataForm'
import appError from 'base/modules/app-error'
import utils from '../modules/utils'
import tax from '../api/tax'

export default {
  name: 'Verification',
  components: {
    UserDataForm
  },
  props: {
    isPassportIssuerCode: {
      type: Boolean,
      default: false
    },
    isBirthPlace: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      files: [],
      sending: false,
      sent: false,
      preventText: '',
      preventComponent: null,
      success: '',
      error: ''
    }
  },
  methods: {
    handleFilesUpload () {
      this.files = this.$refs.files.files
    },
    afterSubmit: function (cart) {
      Vue.$logger.debug('Verification | After submit verification form')
      return new Promise((resolve, reject) => {
        resolve()
      })
    },
    submit: function () {
      this.error = ''

      if (this.$store.state.verification.lastName === '') {
        this.error += '<p>Укажите вашу фамилию</p>'
      }

      if (this.$store.state.verification.firstName === '') {
        this.error += '<p>Укажите ваше имя</p>'
      }

      if (this.$store.state.verification.secondName === '') {
        this.error += '<p>Укажите ваше отчество</p>'
      }

      if (this.$store.state.verification.birthDate === '') {
        this.error += '<p>Укажите дату вашего рождения</p>'
      }

      if (this.isBirthPlace && this.$store.state.verification.birthPlace === '') {
        this.error += '<p>Укажите место вашего рождения</p>'
      }

      if (!utils.validatePhone(this.$store.state.verification.phone)) {
        this.error += '<p>Укажите корректный номер телефона</p>'
      }

      if (!utils.validateEmail(this.$store.state.verification.email)) {
        this.error += '<p>Некорректный email</p>'
      }

      if (this.$store.state.verification.passportSeries === '') {
        this.error += '<p>Укажите ваш серию вашего паспорта</p>'
      }

      if (this.$store.state.verification.passportNumber === '') {
        this.error += '<p>Укажите ваш номер вашего паспорта</p>'
      }

      if (this.isPassportIssuerCode && this.$store.state.verification.passportIssuerCode === '') {
        this.error += '<p>Укажите ваш код подразделения паспорта</p>'
      }

      if (this.$store.state.verification.passportIssuedBy === '') {
        this.error += '<p>Укажите кем выдан ваш паспорт</p>'
      }

      if (this.$store.state.verification.passportIssuedDate === '') {
        this.error += '<p>Укажите дату выдачи вашего паспорта</p>'
      }

      // if (this.$store.state.verification.passportAddress === '') {
      //   this.error += '<p>Укажите адрес вашей прописки</p>'
      // }

      if (this.$store.state.verification.postAddress === '') {
        this.error += '<p>Укажите ваш почтовый адрес</p>'
      }

      if (this.$store.state.verification.inn === '') {
        this.error += '<p>Укажите номер вашего ИНН</p>'
      }

      if (this.$store.state.verification.inn !== '' && !utils.validateInn(this.$store.state.verification.inn)) {
        this.error += '<p>Укажите корректный ИНН</p>'
      }

      // if (this.$store.state.verification.snils === '') {
      //   this.error += '<p>Укажите номер вашего СНИЛС</p>'
      // }

      // if (this.$store.state.verification.snils !== '' && !utils.validateSnils(this.$store.state.verification.snils)) {
      //   this.error += '<p>Укажите корректный СНИЛС</p>'
      // }

      if (this.error === '') {
        const formData = {
          lastName: this.$store.state.verification.lastName,
          firstName: this.$store.state.verification.firstName,
          secondName: this.$store.state.verification.secondName,
          birthDate: utils.formatDate(this.$store.state.verification.birthDate),
          birthPlace: this.$store.state.verification.birthPlace,
          phone: this.$store.state.verification.phone.replace(/[^0-9]/g, ''),
          email: this.$store.state.verification.email,
          passportSeries: this.$store.state.verification.passportSeries.replace(/[^0-9]/g, ''),
          passportNumber: this.$store.state.verification.passportNumber.replace(/[^0-9]/g, ''),
          passportIssuerCode: this.$store.state.verification.passportIssuerCode,
          passportIssuedBy: this.$store.state.verification.passportIssuedBy,
          passportIssuedDate: utils.formatDate(this.$store.state.verification.passportIssuedDate),
          passportAddress: this.$store.state.verification.passportAddress,
          postAddress: this.$store.state.verification.postAddress,
          inn: this.$store.state.verification.inn.replace(/[^0-9]/g, ''),
          snils: this.$store.state.verification.snils.replace(/[^0-9]/g, ''),
          files: this.files
        }

        this.sending = true

        tax.verify(this, formData)
          .then((response) => {
            this.sending = false
            this.success = 'Ваша заявка на подтверждение личности отправлена! После проверки отправленных вами данных вы сможете оформлять заказы.<br>Проверка может занять от 3 до 7 рабочих дней.'
            this.sent = true
            this.afterSubmit(formData)
          }).catch((error) => {
            this.sending = false
            this.error = appError.getMessage(error.message)
            Vue.$logger.debug('Verification | tax.verify | ошибка: ', error)
          })
      }
    },
    afterMounted: function () {
    }
  },
  mounted: function () {
  }
}
</script>

<style lang="css" scoped>
  .file-field input {
    width: 100%;
  }

  .form-group {
    margin-bottom: 6px;
  }

  small {
    background: #f9f9f9;
    padding: 16px;
    display: block;
    font-size: 90%;
  }

  button {
    margin-bottom: 10px;
  }
</style>
