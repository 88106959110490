<template>
  <div>
    <p>Чтобы распечатать акт за {{ year }} год нажмите кнопку &laquo;Распечатать&raquo;.</p>
    <p><b>После того, как вы подпишите распечатанный акт, вам необходимо отсканировать и загрузить его на <router-link class="top-menu__link" active-class="top-menu__link--active" :to="{ name: 'Upload', params: { taskId: $route.params.taskId }}">странице загрузки</router-link>.</b></p>
    <form action="" method="post" enctype="multipart/form-data">
      <div>
        <hr>
        <div class="alert alert--danger" v-if="error">
          <p v-html="error"></p>
        </div>
        <small>Генерация акта происходит в&nbsp;новом окне, поэтому убедитесь, что для сайта разрешено открытие всплывающих окон.</small>
        <br>
        <button class="button button--rounded button--large" @click.prevent="print()">Распечатать</button>
        <br>
        <br>
      </div>
    </form>
    <div class="act-output">
      <p style="text-align: center;">Акт приема-передачи призов № ЭГ000129/_______ <br><br>
      <p><b>Сведения о мероприятии (далее – Мероприятие):</b></p>
      <table width="100%" cellspacing="0" cellpadding="0" border="1">
        <tbody>
          <tr>
            <td width="105" style="vertical-align: top;">
              Наименование
            </td>
            <td width="409">
              {{ $store.state.main.title }}
            </td>
          </tr>
          <tr>
            <td width="105" style="vertical-align: top;">
              Общий срок проведения
            </td>
            <td width="409">
              {{ act.promo_begin }} – {{ act.promo_end }}
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <p><b>Организатор и Представителе:</b></p>
      <table>
        <tbody>
          <tr>
            <td width="105" style="vertical-align: top;">
              Сведения об Организаторе
            </td>
            <td width="409">
              ООО «ГИФТЕРИ.РУ» (119313, ГОРОД МОСКВА, ПРОСПЕКТ ЛЕНИНСКИЙ, ДОМ 95, ЭТ&nbsp;ЦОК ПОМ Х&nbsp;ОФ&nbsp;68, ИНН 7707741006)
            </td>
          </tr>
          <tr>
            <td width="105" style="vertical-align: top;">
              Сведения о Представителе организатора
            </td>
            <td width="409">
              ООО «Эдвертайзинг Гайд» (юридический адрес: РФ, 109012, г. Москва, ул. Ильинка, д. 4, помещение 101-102; Почтовый адрес: РФ, 109012, г. Москва, ул. Ильинка, д. 4, а/я 36; ОГРН 1107746438568; регистрационный номер в реестре операторов персональных данных — 77-14-002059), представитель Организатора в части вручения приза и выполнения функций налогового агента, действующий на основании Договора № ЭГ000129/17-НА от «__» _____ 2017 года
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <p><b>Сведения о победителе Мероприятия (далее - Победитель):</b></p>
      <table>
        <tbody>
          <tr>
            <td width="154" style="vertical-align: top;">
              Ф. И. О.
            </td>
            <td colspan="7" width="362">
              {{ act.lastname }} {{ act.firstname }} {{ act.middlename }}
            </td>
          </tr>
          <tr>
            <td width="154" style="vertical-align: top;">
              Дата рождения
            </td>
            <td colspan="7" width="362">
              {{ act.birthdate }}
            </td>
          </tr>
          <tr>
            <td width="154" style="vertical-align: top;">
              Паспорт
            </td>
            <td width="64" style="text-align: right;">
              <b>Серия</b>
            </td>
            <td colspan="2" width="99">
              {{ act.passport_series }}
            </td>
            <td colspan="2" width="64" style="text-align: right;">
              <b>Номер</b>
            </td>
            <td colspan="2" width="135">
              {{ act.passport_number }}
            </td>
          </tr>
          <tr>
            <td width="154" style="vertical-align: top;">
              Паспорт выдан, дата выдачи
            </td>
            <td colspan="7" width="362">
              {{ act.passport_issue_place }}, {{ act.passport_issue_date }}
            </td>
          </tr>
          <!-- <tr>
            <td width="154" style="vertical-align: top;">
              Адрес регистрации из паспорта
            </td>
            <td colspan="7" width="362">
              {{ act.passport_address }}
            </td>
          </tr> -->
          <tr>
            <td width="154">
              ИНН
            </td>
            <td colspan="7" width="362">
                {{ act.inn }}
            </td>
          </tr>
          <tr>
            <td width="154">
                Контактный телефон
            </td>
            <td colspan="7" width="362">
              {{ act.phone }}
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <p>Представитель, с одной стороны, и Победитель, с другой стороны, подписали настоящий Акт о нижеследующем: <br>
      1. По результатам проведения Мероприятия Представитель передает, а Победитель принимает Главный приз в следующем составе:</p>
      <table>
        <thead>
          <tr>
            <th width="80" style="text-align: center;">
              Пункт
            </th>
            <th width="185" style="text-align: center;">
              Описание приза
            </th>
            <th width="77" style="text-align: center;">
              Цена за единицу (руб.)
            </th>
            <th width="82" style="text-align: center;">
              Количество (шт.)
            </th>
            <th width="91" style="text-align: center;">
              Стоимость (руб.)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(card, index) in act.cards" :key="index">
            <td width="80">
              1.{{ (index + 1) }}
            </td>
            <td width="185">
              {{ card.product }}
            </td>
            <td width="77" style="text-align: center;">
              {{ card.face }}
            </td>
            <td width="82" style="text-align: center;">
              {{ card.quantity }}
            </td>
            <td width="91" style="text-align: center;">
              {{ card.sum }}
            </td>
          </tr>
          <tr>
            <td width="80" style="vertical-align: top;">
              1.{{ overhead_row }}
            </td>
            <td width="185">
              Дополнительный денежный приз, который рассчитывается по формуле: (S-4000)*13/87, где S – это фактическая стоимость имущественной части, указанной в {{ card_rows }} Акта, вручаются в порядке, установленном в п. 2 Акта
            </td>
            <td width="77" style="text-align: center;">
              {{ overhead_formatted }}
            </td>
            <td width="82" style="text-align: center;">
              1
            </td>
            <td width="91" style="text-align: center;">
              {{ overhead_formatted }}
            </td>
          </tr>
          <tr>
            <td colspan="4" width="424" style="text-align: right;">
              <b>Общая стоимость призов:</b>
            </td>
            <td width="91" style="text-align: center;">
              {{ total_formatted }}
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <p>
      2. Организатор по поручению Победителя в соответствии со ст. 226 НК РФ удерживает и перечисляет в бюджет налог на доходы физического лица (далее – НДФЛ), который взимается по ставке 13% от общей стоимости любых выигрышей, получаемых в проводимых конкурсах в целях рекламы товаров, работ и услуг (от общей стоимости призов), в части превышения необлагаемой суммы, установленной в п. 28 ст. 217 НК РФ (в размере 4000 рублей). Победитель согласен на удержание и перечисление в бюджет Организатором из стоимости дополнительной денежной награда суммы НДФЛ, по итогам чего погашается задолженность Победителя перед бюджетом по уплате НДФЛ от выигрышей в полном объёме. Никаких иных налоговых обязанностей в связи с получением призов у Победителя не возникает. <br>
      3. Победитель обязан подписать Акт и отправить копию (скан) Акта <slot name="actPostAddress">по электронному адресу support@giftery.ru</slot>, а оригинал направить почтовым отправлением (или лично) по почтовому адресу: 123458, г. Москва, ул. Твардовского, д.8, стр. 1, Технопарк «Строгино», корпус «Г», офис № 313 в течение 1 (одного) рабочего дня после даты получения Акта, после чего ему присуждается дополнительный денежный приз и сумма НДФЛ перечисляется в бюджет. <br>
      4. Подписывая настоящий Акт, Победитель подтверждает достоверность и принадлежность ему указанных в Акте персональных данных и предоставляет своё согласие на обработку данных в объёме, указанном в правилах проведения Мероприятия и в соответствии с обязанностями, возникающими у Представителя как у налогового агента. <br>
      5. По вопросам уплаты НДФЛ от Призов и получению справок 2-НДФЛ Победитель обращается к Представителю по следующим контактным данным: info@didri.ru, 8-495-532-13-23, РФ, 109012, г. Москва, ул. Ильинка, д. 4, а/я 36, ООО «Эдвертайзинг Гайд».</p>
      <br>
      <br>
      <table class="no-border">
        <tr>
          <td style="vertical-align: bottom; padding-right: 100px;">Генеральный&nbsp;директор&nbsp;Представителя<br>
          ООО «Эдвертайзинг</td>
          <td style="vertical-align: bottom; padding-right: 100px; white-space: nowrap;">____________________</td>
          <td style="vertical-align: bottom; white-space: nowrap;">/Попов А.Е./</td>
        </tr>
        <tr>
          <td style="vertical-align: bottom;">Победитель</td>
          <td style="vertical-align: bottom; padding-right: 100px; white-space: nowrap;">____________________</td>
          <td style="vertical-align: bottom; white-space: nowrap;">/___________________ /</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import tax from '../api/tax'
import appError from 'base/modules/app-error'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { ru } from 'date-fns/locale'
import utils from '../modules/utils'

export default {
  name: 'DownloadAct',
  data () {
    return {
      act: {},
      year: '',
      error: ''
    }
  },
  computed: {
    card_rows () {
      return typeof this.act.cards !== 'undefined' ? this.act.cards.length : 0
    },
    cards_total () {
      if (typeof this.act.cards !== 'undefined') {
        return this.act.cards.reduce(function (accumulator, currentValue) {
          return accumulator + currentValue.sum
        }, 0)
      } else {
        return 0
      }
    },
    total () {
      return this.cards_total + this.overhead
    },
    total_formatted () {
      return utils.numberFormat(this.total, 2, ',', '')
    },
    overhead_row () {
      return typeof this.act.cards !== 'undefined' ? this.act.cards.length + 1 : 1
    },
    overhead () {
      return typeof this.act.cards !== 'undefined' ? (this.cards_total - 4000) * 13 / 87 : 1
    },
    overhead_formatted () {
      return typeof this.act.cards !== 'undefined' ? utils.numberFormat(this.overhead, 2, ',', '') : 1
    }
  },
  methods: {
    print () {
      this.error = ''

      const printWindow = window.open()
      printWindow.document.write('<div class="loading">Подождите, пожалуйста, мы сейчас генерируем акт для вас...</div>')

      tax.act(this, {
        year: this.year
      })
        .then(response => {
          Vue.$logger.info('DownloadAct | tax.downloadAct | акт: ', response)
          this.act = response

          printWindow.document.innerHTML = ''
          const style = `
            html * {
              font-size: 12px;
            }

            .loading {
              display: none;
            }

            p {
              margin: 0;
            }

            table {
              width: 100%;
              border-collapse: collapse;
            }

            table th,
            table td {
              padding: 4px 6px;
              border: 1px solid #000;
            }

            table.no-border th,
            table.no-border td {
              border: none;
            }
          `
          this.$nextTick(() => {
            const styleNode = printWindow.document.createElement('style')
            styleNode.innerHTML = style

            const metaNode = printWindow.document.createElement('link')
            metaNode.name = 'format-detection'
            metaNode.content = 'telephone=no'

            printWindow.document.head.appendChild(styleNode)
            printWindow.document.head.appendChild(metaNode)

            printWindow.document.body.innerHTML = document.getElementsByClassName('act-output')[0].innerHTML

            const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
            if (iOS) {
              utils.downloadFile(printWindow.document.documentElement.innerHTML, 'act.html')
            } else {
              printWindow.print()
              // printWindow.close()
            }
          })
        }).catch((error) => {
          const dateFormat = 'dd MMMM yyyy'
          this.error = error.data && error.data.length
            ? appError.getMessage(error.code, null, `Документы принимаются с
                ${format(parseISO(error.data[0].from), dateFormat, { locale: ru })} до ${format(parseISO(error.data[0].to), dateFormat, { locale: ru })}`)
            : appError.getMessage(error.code)
          Vue.$logger.error('DownloadAct | tax.downloadAct | ошибка: ', error)
          printWindow.close()
        })
    },
    afterMounted: function () {
    }
  },
  created () {
    const currentDate = new Date()
    let year = this.$route.params.year || currentDate.getFullYear()
    if (year < 2017 || year > currentDate.getFullYear()) {
      year = currentDate.getFullYear()
    }
    this.year = year
  },
  mounted () {
  }
}
</script>

<style lang="css" scoped>
  .form-field {
    width: 100%;
    margin-bottom: 15px;
  }

  .form-field--large {
    padding-left: 14px;
    padding-right: 14px;
  }

  @media (min-width: 480px) {
    .form-field--large {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .act-output {
    display: none;
  }

  small {
    background: #f9f9f9;
    padding: 16px;
    display: block;
  }
</style>
