<template>
  <div>
    <div v-if="sent === false">
      <p>Выберите файлы и нажмите кнопку &laquo;Отправить&raquo;</p>
    </div>
    <form action="" method="post" enctype="multipart/form-data">
      <div class="alert alert--danger" v-if="error">
        <p v-html="error"></p>
      </div>
      <div class="alert alert--success" v-if="success">
        <p v-html="success"></p>
      </div>
      <div v-if="sent === false">
        <hr>
        <div class="form-group">
          <div class="file-field">
            <input
              type="file"
              ref="files"
              multiple
              @change="handleFilesUpload()"
              accept="image/jpeg,image/png,image/webp,application/pdf"
            >
          </div>
        </div>
        <small>Вы можете выбрать несколько файлов удерживая клавишу <strong>Ctrl&nbsp;(Windows)</strong> или <strong>Cmd&nbsp;(macOS)</strong>.<br> Общий размер файлов <strong>не&nbsp;должен превышать 30&nbsp;МБ</strong>.</small>
        <br>

        <button class="button button--rounded button--large" @click.prevent="submit()" :disabled="sending">Отправить</button>

        <div class="row">
          <div class="col-sm-8 col-md-7">
            <div class="form__sending-text" v-if="sending">Пожалуйста, подождите. Идёт отправка...</div>
          </div>
        </div>

        <br>
        <br>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from 'vue'
import tax from '../api/tax'
import appError from 'base/modules/app-error'

export default {
  name: 'Upload',
  data () {
    return {
      files: [],
      sending: false,
      sent: false,
      success: '',
      error: ''
    }
  },
  computed: {
  },
  methods: {
    handleFilesUpload () {
      this.files = this.$refs.files.files
    },
    submit: function () {
      this.error = ''

      const formData = {
        files: this.files
      }

      if (typeof this.$route.params.taskId !== 'undefined') {
        formData.task_id = this.$route.params.taskId
      }

      this.sending = true

      tax.upload(this, formData)
        .then((response) => {
          this.sending = false
          this.success = 'Файлы успешно отправлены.<br>Обработка может занять от 3 до 7 рабочих дней.'
          this.sent = true
        }).catch((error) => {
          this.sending = false
          this.error = appError.getMessage(error.message)
          Vue.$logger.error('Upload | tax.upload | ошибка: ', error)
        })
    },
    afterMounted: function () {
    }
  },
  created: function () {
  },
  mounted: function () {
  }
}
</script>

<style lang="css" scoped>
  .form-field {
    width: 100%;
    margin-bottom: 15px;
  }

  .form-field--large {
    padding-left: 14px;
    padding-right: 14px;
  }

  @media (min-width: 480px) {
    .form-field--large {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .file-field input {
    width: 100%;
  }

  .form-group {
    margin-bottom: 6px;
  }

  button {
    margin-bottom: 10px;
  }

  small {
    background: #f9f9f9;
    padding: 16px;
    display: block;
    font-size: 90%;
  }
</style>
