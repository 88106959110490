import Vue from 'vue'
import axios from 'axios'
import * as Sentry from '@sentry/browser'

export default {
  verify (app, payload) {
    const files = payload.files
    delete payload.files

    Vue.$logger.info('tax | Requesting verification', JSON.parse(JSON.stringify(payload)), files)

    const formData = Object.keys(payload).reduce((formData, key) => {
      formData.append(key, payload[key])
      return formData
    }, new FormData())

    Array.from(files).forEach((file, i) => {
      formData.append('files[]', file)
    })

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + app.$store.state.main.code + '/user/tax/verify'

    return axios.post(ACTION_URL, formData, { headers: { ...app.$store.getters['user/authHeader'](), 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        return response.data
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('tax | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else if (error.response.status === 404) {
            throw new Error('action_not_found')
          } else if (error.response.status === 413) {
            throw new Error('files_size_exceeded')
          } else {
            throw new Error('verification_error')
          }
        } else if (error.request) {
          Vue.$logger.error('tax', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },
  upload (app, payload) {
    const files = payload.files
    delete payload.files

    Vue.$logger.info('tax | Uploading files', JSON.parse(JSON.stringify(payload)), files)

    const formData = Object.keys(payload).reduce((formData, key) => {
      formData.append(key, payload[key])
      return formData
    }, new FormData())

    Array.from(files).forEach((file, i) => {
      formData.append('files[]', file)
    })

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + app.$store.state.main.code + '/user/tax/upload'

    return axios.post(ACTION_URL, formData, { headers: { ...app.$store.getters['user/authHeader'](), 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        return response.data
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('tax | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else if (error.response.status === 404) {
            throw new Error('action_not_found')
          } else if (error.response.status === 413) {
            throw new Error('files_size_exceeded')
          } else {
            throw new Error('upload_error')
          }
        } else if (error.request) {
          Vue.$logger.error('tax', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },
  act (app, payload) {
    Vue.$logger.info('tax | Downloading act', payload)

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + app.$store.state.main.code + '/user/tax/act/' + payload.year

    return axios.get(ACTION_URL, { headers: app.$store.getters['user/authHeader']() })
      .then((response) => {
        Vue.$logger.info('tax | Success: ', response)
        return response.data
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('tax | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else if (error.response.status === 404) {
            throw new Error('act_not_found')
          } else {
            throw new Error('act_error')
          }
        } else if (error.request) {
          Vue.$logger.error('tax', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  }
}
